$accent-color: rgb(0, 120, 216);
$secondary-color: rgba(0, 0, 0, 0.253);
$footer-height: 60px;

body{
  padding: 0;
  margin: 0;
  height: 100vh;
  font-family: 'Roboto', sans-serif;
}

#root{
  margin:0;
  position: relative;
  min-height: 100vh;
  padding-bottom: $footer-height;
}

h1{
  margin-top: 5px;
  text-align: center;
  font-size: xx-large;
  text-transform: uppercase;
  color: white !important;
  background-color: rgba($accent-color, .75);
  padding-top: 5px;
  padding-bottom: 5px;
}

div.App{
  text-align: left;
  margin-left: 30px;
  margin-right: 30px;
}

div.title{
  font-size: x-large;
  color: #ccc;
  text-transform: uppercase;
  font-weight: 600;
  transition: all 1s ease-in-out;
  position: relative;
}

div.title:hover{
  content: attr(data-item);
  transition: all 1s ease-in-out;
  color: $accent-color;
}

table {
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table caption {
  font-size: 1.5em;
  margin: .5em 0 .75em;
}

table tr {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: .35em;
}

table th,
table td {
  padding: .625em;
  text-align: center;
}

table th {
  background-color: rgba($accent-color, .5);
  color: white;
  font-size: .85em;
  letter-spacing: .1em;
  text-transform: uppercase;
}

@media screen and (max-width: 600px) {
  table {
    border: 0;
  }

  table caption {
    font-size: 1.3em;
  }
  
  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
  table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .625em;
  }
  
  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: .8em;
    text-align: right;
  }
  
  table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  table td:last-child {
    border-bottom: 0;
  }
}

header {
  height: auto;
}

nav {
  margin: 0;
  padding: 0;
}

nav.menuItems ul{
    list-style: none;
    display: flex;
}
nav.menuItems li {
    margin: 10px;
}
nav.menuItems li a {
        text-decoration: none;
        color: #8f8f8f;
        font-size: 24px;
        font-weight: 400;
        transition: all 0.5s ease-in-out;
        position: relative;
        text-transform: uppercase;
        cursor: pointer;
}

nav.menuItems li a:hover{
    content: attr(data-item);
    transition: 0.5s;
    color: $accent-color;
    width: 100%;
    transition: all 0.5s ease-in-out;
}

footer {
  font-size: 12px;
  bottom: 0;
  width: 100%;
  height: 60px;
  line-height: 60px;
  font-size: 14px;
  background-color: #f1f1f1;
  color: #000000;
  text-align: center;
  display: block;
  clear: both;
}

footer a {
    text-decoration: none;
    color: inherit;
    border-bottom: 1px solid;
}
footer a:hover {
  border-bottom: 1px transparent;
}

div.actions{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

div.btn{
  flex: 0 0 auto;
  border: 1px solid $accent-color;
  border-radius: 5px;
  padding: 5px;
  background-color: $accent-color;
  clear:both;
  margin-bottom: 5px;
  text-align: center;
}

div.btn a{
  text-decoration: none;
  color: white;
}

pre{
  white-space: pre-wrap;
  overflow-x: auto;
}

label {
  display: table-cell;
  vertical-align: top;
  padding: 0 .5em .5em;
  min-width: 10%;
  font-weight: bold;
}

input, select{
  flex-grow: 1;
}

input[type='submit']{
  flex-grow: 0;
}

.action-btn{
  padding: 5px;
  background-color: $accent-color;
  color: white;
  text-decoration: none;
  cursor: pointer;
  margin-right: 3px;
}

.action-btn:hover{
  text-decoration: underline;
}

div.multiform-navigation{
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 0;
  margin-right: 0;
  display: block;
  overflow: auto;
}

div.subFormPart{
  margin-top: 15px;
}

div.progressArea{
  text-align: center;
  padding: 10px;
}

form h4, .race-information h4{
  color: $accent-color;
  margin-top: 10px;
  margin-bottom: 10px;
  border-bottom: 0.5px solid $accent-color
}

.btn-theme{
  color: white;
  background-color: $accent-color;
  border: 1px solid $accent-color;
  border-radius: 3px;
}

.btn-theme:hover{
  color: $accent-color;
  background-color: white;
}

#select-riders, #select-causes{
  padding: 15px;
  background-color: rgba($accent-color, .10);
  border-radius: 3px;
  margin-bottom: 10px;
}

div.incident-actions>*{
  margin-right: 5px;
}

div.loginform{
  display: flex;
  justify-content: center;
}

div.loginform form{
  width: 75%;
}


div.App{

}

footer{
  position: absolute;
  bottom: 0;
  width: 100%;
  height: $footer-height;
  line-height: $footer-height;
  background-color: #f5f5f5;
}

footer>*{
  margin:0;
}

h1,h2,h3,h4,h5{
  color: $accent-color;
}

h4{
  font-size: large;
}

h3{
  font-size: large;
}

h5{
  font-size: medium;
}

h6{
  font-size: medium !important;
}

div.MuiPaper-root{
  margin-bottom: 10px;
}

table.incidentCauses{
  margin-bottom: 10px;
}

ul.raceDetails{
  list-style-type: none;
}

.btn-nav{
  margin-left:5px;
  margin-right: 5px;
  color: white;
  font-size: small;
}

.btn-nav:hover{
  color:white;
}

div.course-details, div.id-lab-metrics{
  display: flex;
  flex-wrap: wrap;
  flex: 0 1 auto;
}
span.info-tab{
  border: 1px solid $accent-color;
  border-radius: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  padding: 0;
}

span.tab-header{
  height: 45px;
  display: block;
  margin: 0;
  padding: 0;
  background: $accent-color;
  color: white;
}

span.tab-header-description{
  font-size: small;
  display: block;
  text-align: center;
}

.tab-header-icon{
  height: 25px;
  width: 100%;
}

span.tab-content{
  display: block;
  text-align: center;
  font-size: medium;
  margin: 4px;
}

div.bicycle-loader{
  position: absolute;
  top: 0;
  left: 0;
  width: 150px;
  height: 150px;
  text-align: center;
  z-index: 10000000;
  overflow: hidden;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
  right: 0;
  bottom: 0;
  background: rgba($accent-color, .9);
  border-radius: 5px;
  font-size: large;
  text-transform: uppercase;
  font-weight: bold;
}

div.bicycle-loader img{
  width: 150px;
  height: auto;
}

  .elevation-chart-wrapper {
    border-radius: 2px 2px 0 0;
    padding: 24px 0 8px 12px;
    /* padding: 16px 0 8px 12px; */
    background: white;
    position: absolute;
    /* right: 340px;
    bottom: unset;
    top: 0; */
    top: unset;
    bottom: 50px;
    left: 50px;
    z-index: 5;
    .chevron-icon-wrapper {
      cursor: pointer;
      width: 50px;
    }
    .chart-icon-wrapper {
      position: absolute;
      bottom: 12px;
    }
    span.chevron-icon {
      position: absolute;
      top: 0.25rem;
      left: 0.75rem;
      height: 1.5rem;
      width: 1.5rem;
    }
  }
  text {
    font-size: 12px;
    stroke: none;
    fill: #999;
  }
  text.crossBarText {
    fill: #666;
    width: 200px;
  }
  path.domain {
    fill: none;
    stroke: #aaa;
  }
  g.tick {
    line {
      color: transparent;
      stroke: #eee;
      stroke-opacity: 1;
    }
  }
  .crossBar line {
    stroke: #333;
    stroke-width: 1px;
    pointer-events: none;
    shape-rendering: crispEdges;
  }
  .chartOverlay {
    fill: none;
    pointer-events: all;
  }
  .infoBox rect {
    stroke: #ccccd1;
    pointer-events: none;
    stroke-width: 1px;
    shape-rendering: crispEdges;
    font-size: 11px;
    fill: #fff;
    fill-opacity: 0.9;
  }
  tspan,
  text.crossBarText {
    font-size: 15px;
  }
  tspan {
    fill: #2d2d32;
  }
  .infoBoxElevationValue,
  .infoBoxGradeValue {
    font-weight: 600;
  }
  .elevationChartGrid line {
    stroke: lightgrey;
    stroke-opacity: 0.7;
    shape-rendering: crispEdges;
  }
  .elevationChartGrid path {
    stroke-width: 0;
  }

  #chartjs-tooltip{
    background: rgba($accent-color, .75);
    color: white;
  }

  #chartjs-tooltip p{
    margin-bottom: 2px;
  }

  div.course-turns{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  div.turn{
    flex: 1 0 auto;
    min-width: 150px;
    display: block;
    cursor: pointer;
    background-color:rgb(203, 225, 250);
    border-radius: 10px;
    margin-left: 4px;
    color: black;
  }

  div.turn div.turn-icon{
    font-size: xx-large;
  }

  div.turn div.turn-distance-done p:first-child{
    font-size: x-small;
  }
  div.turn div.turn-distance-done p:nth-child(2){
    font-size: large;
    font-weight: bold;
  }

  div.turn *{
    text-align: center;
    margin: 0;
    padding: 3px;
  }

  div.turn div.turn-distance-done{
    font-size: small;
  }

  div.turn-point{
    border-radius: 10px;
    height: 10px;
    width: 10px;
    background: blue;
    display: inline-block;
  }

  div.turn-options{
    width: 100%;
    display: inline-block;
  }

  div.turn-options button{
    display: inline;
    float: left;
    height: 38px;
    margin-left: 3px;
  }

.speed-indication{
  width: 100px;
  max-width: 150px;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  position: relative;
  vertical-align: middle;
  left: 10px;
  top: 0;
  overflow: hidden;
  display: inline-block;
}

.gauge__body {
  width: 100%;
  height: 0;
  padding-bottom: 50%;
  background: #b4c0be;
  position: relative;
  border-top-left-radius: 100% 200%;
  border-top-right-radius: 100% 200%;
  overflow: hidden;
}

.gauge__fill {
  position: absolute;
  top: 100%;
  left: 0;
  width: inherit;
  height: 100%;
  background: #009578;
  transform-origin: center top;
  transform: rotate(0.25turn);
  transition: transform 0.2s ease-out;
}

.gauge__cover {
  width: 75%;
  height: 150%;
  background: #ffffff;
  border-radius: 50%;
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translateX(-50%);

  /* Text */
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 25%;
  box-sizing: border-box;
}

div.straightness-ratios{
  display: flex;
  flex-direction: row;
}

div.straightness-ratio{
  flex: 1
}

.site-loader,
.site-loader:before,
.site-loader:after {
  background: #005faa;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 6em !important;
}
.site-loader {
  color: #005faa;
  text-indent: -9999em;
  margin: 15px 15px;
  position: relative;
  font-size: 3px;
  height: 4em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.site-loader:before,
.site-loader:after {
  position: absolute;
  top: 0;
  content: '';
}
.site-loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.site-loader:after {
  left: 1.5em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}

div.osmTable{
  max-height: 65vh;
  overflow-y: scroll;
}

.btn-chart{
  font-size: x-small;
  float: right;
  background-color: rgb(151, 98, 0) !important;
  border: #000000 !important;
}